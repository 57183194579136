<template>
  <div>
    <div class="d-flex align-items-center">
      <h1 class="h3">
        Atur Pembelian
      </h1>
      <div class="ml-auto">
        <a-button type="primary" size="large" @click="toAddPurchaseOrder()">
          <a-icon type="plus-square" />
          Buat Pembelian
        </a-button>
      </div>
    </div>
    <div class="mt-4">
      <a-table
        class="table-list"
        :data-source="purchaseOrders"
        :row-key="record => record.id"
        :columns="columns"
        :loading="isLoading"
        :pagination="false"
        :scroll="scroll"
      >
        <template slot="supplierDO" slot-scope="text">
          {{ text || '-' }}
        </template>
        <template slot="PODate" slot-scope="text">
          {{ text ? $moment(text).format('DD MMM YYYY') : '-' }}
        </template>
        <template slot="requestDeliveryDate" slot-scope="text">
          {{ text ? $moment(text).format('DD MMM YYYY') : '-' }}
        </template>
        <template slot="status" slot-scope="text">
          <a-tag v-if="text" :color="stateColorTag[text]">
            {{ text?.toUpperCase() }}
          </a-tag>
          <span v-else> - </span>
        </template>
        <template slot="warehouseId" slot-scope="text">
          {{ findWarehouse(text, 'name') }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a-dropdown>
            <a @click.prevent="">
              <a-icon type="more" />
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item key="1" class="py-2" @click="onShowModalDetailPO(record)">
                  <a-icon type="eye" />
                  {{ $t('store_list_page.view_detail') }}
                </a-menu-item>
                <a-menu-item key="2" class="py-2" @click="onConfirmDelete(record)">
                  <!-- <a-popconfirm
                    placement="left" 
                    :title="$t('po.cancel_description')"
                    @confirm="() => onConfirmDelete(record)"
                  >
                  </a-popconfirm> -->
                  <a-icon type="delete" />
                  {{ $t('po.cancel') }}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-table>
    </div>
    <div class="mt-4 text-right">
      <a-pagination
        v-model="params.page"
        :page-size="params.size"
        :page-size-options="sizeOptions"
        :total="totalRow"
        :show-total="(total, range) => $t('utils.pagination_show_total', { perpage: `${range[0]} - ${range[1]}`, total })"
        :disabled="isLoading"
        show-size-changer
        @change="onPageChange"
        @showSizeChange="onShowSizeChange"
      />
    </div>

    <ModalDetailPO
      :data="detailDataPO"
      :is-loading="isLoading"
      :visible="showModalDetailPO"
      :business-id="businessId"
      @onCancel="showModalDetailPO = false, detailDataPO = {}"
    />
  </div>
</template>

<script>
export default {
  name: 'ListPurchaseOrder',
}
</script>
<script setup>
import debounce from 'lodash/debounce'
import { computed, getCurrentInstance, onMounted, reactive, ref, toRefs, watch } from 'vue'
import { getListPO, deletePO } from '@/api/purchase-order'
import { columnsPurchaseOrder } from '@/data/columns'
import ModalDetailPO from '@/views/purchase-order/modal/ModalDetailPO.vue'
import { Modal } from 'ant-design-vue';

const { $i18n, $route, $router, $store, $moment, $notification } = getCurrentInstance().proxy.$root

const INITIAL_SORT = {
  sort: ['createdAt'],
  order: ['DESC'],
}

const STATE_COLOR_TAG = {
  CANCELED: 'red',
}
const INITIAL_PARAMS = {
  search: '',
  start_date: '',
  end_date: '',
  status: 'ALL',
  sort: 'createdAt',
  order: 'DESC',
  page: 1,
  size: 10,
}

// Data
const screenHeight = ref(((screen.height - 900) * 0.4) + (screen.height * 0.4))
const isLoading = ref(false)
const showModalDetailPO = ref(false)
const columns = ref(columnsPurchaseOrder)
const purchaseOrders = ref([])
const warehouseList = ref([])
const totalRow = ref(0)
const selectedDiscounts = ref([])
const detailDataPO = ref({})
const sizeOptions = ref(['10', '20', '30', '40', '50'])
const sorting = reactive(INITIAL_SORT)
const params = reactive(INITIAL_PARAMS)
const stateColorTag = ref(STATE_COLOR_TAG)

// Refs
const refSorting = toRefs(sorting)
const refParams = toRefs(params)

// Computed
const businessId = computed(() => {
  return $store.state.user.restriction_base === 'Warehouse'
    ? Object.keys($store.getters['user/businessListObjectByKey'])[0]
    : $route.query.business_id
})
const scroll = computed(() => ({ y: screenHeight.value > 360 ? screenHeight.value : 360, x: '1000px' }))
const tHeight = computed(() => {
  return !purchaseOrders.value.length ? '0px'
    : screenHeight.value > 360 ? `${screenHeight.value}px`
    : '360px'
})

// Methods
const toAddPurchaseOrder = () => {
  const query = {
    ...$route.query,
  }
  $router.push({
    path: `/purchase-order/create`,
    query,
  })
}

const init = () => {
  selectedDiscounts.value = []
  refSorting.value = INITIAL_SORT
  refParams.value = INITIAL_PARAMS
  fetchWarehouseList()
  fetchListPO()
}

const fetchListPO = debounce(async () => {
  isLoading.value = true
  const selectedBusinessId = $route.query.business_id || businessId.value
  getListPO({
    business_id: selectedBusinessId,
    params: {
      ...params,
      business_id: selectedBusinessId,
    },
  })
  .then(({ data }) => {
    purchaseOrders.value = data?.data || []
    totalRow.value = data?.total_items || 0
  })
  .catch(() => {
    purchaseOrders.value = []
    totalRow.value = 0
  })
  .finally(() => isLoading.value = false)
}, 500)

const onPageChange = (pageNumber) => {
  selectedDiscounts.value = []
  params.page = pageNumber
  fetchListPO()
}

const onShowSizeChange = (current, pageSize) => {
  selectedDiscounts.value = []
  params.page = current
  params.size = pageSize
  fetchListPO()
}


const fetchWarehouseList = () => {
  const selectedBusinessId = $route.query.business_id || businessId.value
  $store.dispatch('warehouse/GETWAREHOUSELIST', {
      ...$route.query,
      business_id: selectedBusinessId,
    })
    .then(response => {
      warehouseList.value = response
    })
    .catch(err => {
      console.error(err)
      return err
    })
}
const findWarehouse = (id, field) => {
  const warehouse = warehouseList.value.find((item) => item.id === id)
  return warehouse ? warehouse[field] : '-'
}
const onDeletePO = (record) => {
  
}
const onShowModalDetailPO = (record) => {
  detailDataPO.value = {
    ...record,
    whs_name: findWarehouse(record.warehouse_id, 'name'),
  }
  showModalDetailPO.value = true
}
const onConfirmDelete = (record) => {
  Modal.confirm({
    content: $i18n.t('po.cancel_description'),
    okText: $i18n.t('po.cancel_confirm'),
    cancelText: $i18n.t('utils.close'),
    onOk: () => {
      const { business_id, id, number } = record
      const payload = {
        po_id: id,
        po_number: number,
        business_id,
      }
      return deletePO({
        business_id,
        data: payload,
      })
      .then(({ data }) => {
        $notification.success({
          message: 'Berhasil',
          description: `PO ${data.data.poNumber ? 'dengan no. ' + data.data.poNumber : ''} Berhasil dibatalkan`,
        })
        fetchListPO()
      })
      .catch(err => {
        console.error(err)
        $notification.error({
          message: 'Gagal',
          description: err.response?.data?.data || 'Terjadi kesalahan saat membatalkan PO',
        })
      })
    },
  });
};
// Watch
watch(() => 
  businessId.value, (newValue) => {
    businessId.value = newValue
    init()
  })
watch(() => 
  $route, (newValue) => {
    if (newValue.name === $route.name) {
      init()
    }
  })

// Mounted
onMounted(init)
</script>

<style lang="scss">
.fs-14 {
  font-size: 14px !important;
}
.fw-bold {
  font-weight: bold;
}
.text-capitalize {
  text-transform: capitalize
}
.sorting-checkbox {
  .ant-checkbox-group-item {
    display: block !important;
    padding: 5px 0;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .ant-checkbox-inner {
    border-color: transparent !important;
  }
  .ant-checkbox-checked {
    &::after {
      border-color: transparent !important;
    }
    .ant-checkbox-inner {
      background-color: transparent !important;

      &::after {
        border-color: #ff0a54 !important;
      }
    }
  }
}
.dropdown-title {
  font-size: 12px;
  font-weight: bold;
  color: #6c757d;
}
.table-list div.ant-table-body {
  min-height: v-bind(tHeight);
}
.ant-btn-info {
  color: #2196f3 !important;
  border-color: #2196f3 !important;
}
.ant-dropdown-menu-item {
  background: #fff !important;
}
.ant-table-row {
  background-color: #fff !important;

  &.ant-table-row-selected > td {
    background-color: #fff !important;
  }
}
.pca-tab-store {
  .ant-tabs-bar {
    background-color: #fff;
    margin-bottom: 0;
    border-bottom-color: transparent;
  }
  .ant-tabs-nav .ant-tabs-tab:hover,
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #ff0a54;
  }
  .ant-tabs-ink-bar {
    background-color: #ff0a54;
  }
}
.select-antd-default.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;
}
</style>