<template>
  <a-modal
    body-style="height: calc(100vh - 300px); overflow-y: scroll;"
    width="1000px"
    title="Detail PO"
    :visible="visible"
    :closable="false"
  >
    <a-spin :spinning="isLoading" tip="Mohon tunggu, sedang membuat data PO...">
      <a-form-model
        ref="ruleForm"
        :model="form"
      >
        <a-card class="w-100" :bordered="false">
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-model-item label="No. PO" prop="poNumber">
                <a-input
                  v-model.trim="form.poNumber"
                  size="large"
                  autocomplete="off"
                  placeholder="Masukan nomor PO"
                  disabled
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="Tanggal PO" prop="poDate">
                <a-date-picker
                  v-model="form.poDate"
                  size="large"
                  class="w-100"
                  :format="dateFormat"
                  placeholder="Masukan tanggal PO"
                  disabled
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-model-item label="Nomor Surat Jalan" prop="deliveryNumber">
                <a-input
                  v-model.trim="form.deliveryNumber"
                  size="large"
                  autocomplete="off"
                  placeholder="Masukan nomor surat jalan"
                  disabled
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="Tanggal Pengiriman" prop="deliveryDate">
                <a-date-picker
                  v-model="form.deliveryDate"
                  size="large"
                  class="w-100"
                  :format="dateFormat"
                  placeholder="Masukan tanggal pengiriman"
                  disabled
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-model-item label="Supplier" prop="supplierCode" class="mb-0">
                <a-input
                  v-model="form.supplierCode"
                  size="large"
                  class="select-antd-default"
                  placeholder="Pilih supplier"
                  disabled
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item :label="$t('sideBar.warehouse')" prop="warehouseId" class="mb-0">
                <a-input
                  v-model="form.warehouseId"
                  size="large"
                  class="select-antd-default"
                  placeholder="Pilih gudang"
                  disabled
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-card>

        <!-- Detail Item Produk -->
        <a-card title="Detail Item Produk" class="w-100 mt-4" :bordered="false">
          <a-row :gutter="24">
            <a-col :span="24">
              <div>
                <div class="d-flex align-items-center justify-content-end mb-3">
                  <div>Total SKU: <b>{{ itemList.length }}</b></div>
                </div>
                <a-table
                  :columns="columns"
                  :row-key="(record, index) => index"
                  :data-source="itemList"
                  :loading="isLoading"
                  :pagination="false"
                  :scroll="{ y: 330 }"
                >
                  <template slot="price" slot-scope="text">
                    <div class="d-flex align-items-center">
                      <div class="mr-2">
                        Rp. {{ toCurrency(text) }}
                      </div>
                    </div>
                  </template>
                  <template slot="catalog_id" slot-scope="text, record">
                    <div class="d-flex flex-column product-select">
                      <div>{{ record?.title }}</div>
                      <div class="sku-desc">
                        {{ 'SKU : ' + ellipsisText(record?.sku) }}
                      </div>
                    </div>
                  </template>
                  <template slot="quantity" slot-scope="text, record">
                    <a-input
                      v-model="record.quantity"
                      :disabled="!record.catalog_id"
                      size="large"
                      type="number"
                      :min="0"
                      autocomplete="off"
                      placeholder="Qty"
                      @change="onChangeInputCart($event, index, 'quantity')"
                      @keypress="numberOnly"
                    />
                  </template>
                  <template slot="uom" slot-scope="text">
                    {{ text }}
                  </template>
                </a-table>
              </div>
            </a-col>
          </a-row>
        </a-card>
      </a-form-model>
    </a-spin>
    <template #footer>
      <template>
        <a-button
          type="primary"
          :loading="fetchingDetailPO"
          @click="closeModal"
        >
          Tutup
        </a-button>
      </template>
    </template>
  </a-modal>
</template>

<script>
import { getDetailPO } from '@/api/purchase-order'
import { columnsProductDetailPO } from '@/data/columns'

export default {
  name: 'ModalDetailPO',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    businessId: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      columns: columnsProductDetailPO,
      dateFormat: 'DD MMM YYYY',
      isLoading: false,
      fetchingDetailPO: false,
      form: {
        poNumber: undefined,
        deliveryNumber: undefined,
        poDate: undefined,
        deliveryDate: undefined,
        supplierCode: undefined,
        warehouseId: undefined,
      },
      itemList: [],
    }
  },
  computed: {
  },
  watch: {
    visible: {
      deep: true,
      immediate: false,
      handler(val) {
        if (val) {
          this.fetchDetailDataPO()
        }
      },
    },
    data: {
      deep: true,
      immediate: false,
      handler(val) {
        this.form.poNumber = val.number
        this.form.deliveryNumber = val.supplier_do
        this.form.poDate = val.po_date
        this.form.deliveryDate = val.request_delivery_date
        this.form.supplierCode = val.supplier_code
        this.form.warehouseId = val.whs_name
      },
    },
  },
  methods: {
    closeModal() {
      this.$emit('onCancel', false)
    },
    ellipsisText(text) {
      if(!text) return '-'
      return text.length > 29 ? `${text.substring(0, 26)}...` : text
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
    fetchDetailDataPO() {
      this.fetchingDetailPO = true
      getDetailPO({
        business_id: this.businessId,
        id: this.data.id,
      })
      .then(({ data }) => {
        this.itemList = data
        this.fetchingDetailPO = false
      })
      .catch(() => {
        this.fetchingDetailPO = false
      })
    },
  },
}
</script>

<style lang="scss" module>
</style>
<style lang="scss">
.image-spin {
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}
</style>